import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import {
  ControlLabel,
  FormRow,
  FormTwoCol,
  FormCol,
  Field,
  CustomValidationMessage,
  RequiredTriangle,
} from "../../components/FormStyle"
import { Checkbox, HelpText, ExploreMore } from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"
import styled from "styled-components"

const CustomRequired = styled(CustomValidationMessage)`
  top: 75px;
  left: -20px;
  z-index: 1;
`

class GetTouchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      state: "",
      city: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      stateRequire: false,
      cityRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  handleChange(event) {
    const { id, value } = event.target

    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }

    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }

    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    let utm_source_val = "Website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    event.preventDefault()
    const { location, formName } = this.props

    if (this.state.first_name === "" || this.state.first_name.trim() === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (
      this.state.last_name === "" ||
      this.state.last_name.trim() === ""
    ) {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "" || this.state.email.trim() === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (
      this.state.phone_no === "" ||
      this.state.phone_no.trim() === ""
    ) {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.state === "" || this.state.state.trim() === "") {
      this.setState({
        stateRequire: true,
      })
    } else if (this.state.city === "" || this.state.city.trim() === "") {
      this.setState({
        cityRequire: true,
      })
    } else if (this.state.comment === "" || this.state.comment.trim() === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state,
        city: this.state.city,
        comment:
          this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
      }
      //console.log(data);
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          if (typeof dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "form_submit",
              formName: "website_forms",
              enhanced_conversion_data: {
                email: this.state.email,
                phone_number: this.state.phone_no,
              },
            })
          }
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state: "",
            city: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thank-you-get-in-touch/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    return (
      <form id="quick-form" onSubmit={this.handleSubmit}>
        <FormRow>
          <FormTwoCol>
            <Field>
              <ControlLabel>First Name</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="first_name"
                name="first_name"
                placeholder="Please Enter Your First Name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                aria-label="first name"
                required
              />
              <CustomRequired
                display={this.state.fNameRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <ControlLabel>Last Name</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="last_name"
                name="last_name"
                placeholder="Please Enter Your Last Name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                aria-label="last name"
                required
              />
              <CustomRequired
                display={this.state.lNameRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <ControlLabel>Email Address</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="email"
                name="email"
                placeholder="Please Enter Your Email Address"
                onChange={this.handleChange}
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                aria-label="email"
                required
              />
              <CustomRequired
                display={this.state.emailRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <ControlLabel>Phone Number</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="phone_no"
                name="phone_no"
                placeholder="Tell us your Personal Number"
                onChange={this.handleChange}
                pattern="[0-9]{10}$"
                aria-label="phone number"
                required
              />
              <CustomRequired
                display={this.state.phoneRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <ControlLabel>State</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="state"
                name="state"
                placeholder="Please Enter Your State"
                onChange={this.handleChange}
                aria-label="state"
                required
              />
              <CustomRequired
                display={this.state.stateRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <ControlLabel>City</ControlLabel>
              <input
                className="form-control bg-transparent"
                id="city"
                name="city"
                placeholder="Please Enter Your City"
                onChange={this.handleChange}
                aria-label="city"
                required
              />
              <CustomRequired
                display={this.state.cityRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormTwoCol>

          <FormCol>
            <Field>
              <ControlLabel>Comment</ControlLabel>
              <textarea
                className="form-control bg-transparent"
                id="comment"
                name="comment"
                placeholder="Write your comment here…"
                rows="2"
                onChange={this.handleChange}
                required
                aria-label="comment"
              />
              <CustomRequired
                display={this.state.commentRequire ? "block" : "none"}
              >
                <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Checkbox>
                <label>
                  <input
                    className="form-control bg-transparent"
                    type="checkbox"
                    id="agreeViaSms"
                    name="agreeViaSms"
                    defaultChecked={true}
                    onChange={this.handleChange}
                    aria-label="contacted via SMS"
                  />
                  {` `}I agree that I will be contacted via SMS.
                </label>
              </Checkbox>
              <HelpText>
                By submitting this form, I agree to receive text messages from
                Probuilt Steel Buildings. Standard messaging rates and data
                charges may apply. Message frequency may vary. You can opt-out
                by replying STOP at any time or reply Help to get more
                information.{" "}
              </HelpText>
            </Field>
          </FormCol>
          <FormCol>
            <ExploreMore>
              <button type="submit" className="btn-lg">
                <PrimaryButton text="Submit" />
              </button>
            </ExploreMore>
          </FormCol>
        </FormRow>
      </form>
    )
  }
}

export default GetTouchForm
